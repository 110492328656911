import { Keyboard, Navigation } from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import { useEffect, useRef, useState } from "react";

import I1 from "../../images/building.JPG";
import I10 from "../../images/SpecialRoom.JPG";
import I10S from "../../images/crop/SpecialRoom.JPG";
import I1S from "../../images/crop/building.JPG";
import I2 from "../../images/pediatricOPD.JPG";
import I2S from "../../images/crop/pediatricOPD.JPG";
import I3 from "../../images/Dental.JPG";
import I3S from "../../images/crop/Dental.JPG";
import I4 from "../../images/Ganesh.JPG";
import I4S from "../../images/crop/Ganesh.JPG";
import I5 from "../../images/InhousePharmacy.JPG";
import I5S from "../../images/crop/InhousePharmacy.JPG";
import I6 from "../../images/NICU.JPG";
import I6S from "../../images/crop/NICU.JPG";
import I7 from "../../images/reception.JPG";
import I7S from "../../images/crop/reception.JPG";
import I8 from "../../images/PICU.JPG";
import I8S from "../../images/crop/PICU.JPG";
import I9 from "../../images/SemiSpecialRoom.JPG";
import I9S from "../../images/crop/SemiSpecialRoom.JPG";
import { motion } from "framer-motion"

const Images = [
  I1S, I2S, I3S, I4S, I5S, I6S, I7S, I8S, I9S, I10S
];

const ImagesZoomed = [
  I1, I2, I3, I4, I5, I6, I7, I8, I9, I10
];

const ImageTitle = [
  "",
  "Pediatric OPD",
  "Pedia Dental OPD",
  "",
  "Pharmacy",
  "NICU",
  "Reception",
  "PICU",
  "Semi Special Room",
  "Special Room"
];

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const singleImageViewRef = useRef(null);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return (() => {
      document.removeEventListener('keydown', handleKeyDown)
    });
  });

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [singleImageViewRef]);

  // Handler for click outside of account drop down
  const handleClickOutside = (event) => {
    if (singleImageViewRef.current && !singleImageViewRef.current.contains(event.target)){
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 0)
    }
  }, [isOpen]);

  const handleKeyDown = (event) => {
    if (isOpen) {
      if (event.key === "ArrowLeft") {
        if (selectedImageIndex > 0) {
          setSelectedImageIndex(selectedImageIndex - 1);
        }
      } else if (event.key === "ArrowRight") {
        if (selectedImageIndex < Images.length-1) {
          setSelectedImageIndex(selectedImageIndex + 1);
        }
      } else if (event.key === "Escape") {
        setIsOpen(false);
      }
    }
  }

  const handleImageClick = (image, index) => {
    setIsOpen(true)
    setSelectedImageIndex(index)
  }

  const renderGridBox = (image, index) => {
    const imageBoxClassName = "mx-[5px] rounded sm:h-[300px] sm:w-[300px] h-[100px] w-[100px] overflow-hidden";
    const imageClassName = "w-auto h-full cursor-pointer";
    return (
      <div className={imageBoxClassName} onClick={handleImageClick.bind(null, image, index)}>
        <img src={image} alt="Icon" className={imageClassName}/>
      </div>
    )
  }

  const renderGrid = () => {
    const wrapperClassName = "flex justify-center mb-[10px]"
    return (
      <div>
        <div className={wrapperClassName}>
          {renderGridBox(Images[0], 0)}
          {renderGridBox(Images[1], 1)}
          {renderGridBox(Images[2], 2)}
        </div>
        <div className={wrapperClassName}>
          {renderGridBox(Images[3], 3)}
          {renderGridBox(Images[4], 4)}
          {renderGridBox(Images[5], 5)}
        </div>
        <div className={wrapperClassName}>
          {renderGridBox(Images[6], 6)}
          {renderGridBox(Images[7], 7)}
          {renderGridBox(Images[8], 8)}
        </div>
        <div className={wrapperClassName}>
          {renderGridBox(Images[9], 9)}
        </div>
      </div>
    );
  }

  const renderSingleImage = () => {
    return (
      <div className="fixed top-0 left-0 justify-center flex w-full h-full bg-gray-1/75 overflow-y-scroll">
        <motion.div 
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1}}
        >
          <div className="mt-[150px]" ref={singleImageViewRef}>
            <Swiper
              className="w-full max-w-[800px] h-[300px] sm:h-[600px]"
              spaceBetween={30}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Keyboard, Navigation]}
              initialSlide={selectedImageIndex}
              keyboard={{
                enabled: true
              }}
            >
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[0]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[1]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[2]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[3]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[4]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[5]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[6]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[7]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[8]} alt="Icon" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full flex justify-center">
                  <img src={ImagesZoomed[9]} alt="Icon" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </motion.div>
      </div>
    );
  }
 
  return (
    <div className="m-[10px]">
      <div className="text-3xl text-purple-1 mb-[40px]">Gallery</div>
      {renderGrid()}
      {isOpen && renderSingleImage()}
    </div>
  )
}

export default Gallery;