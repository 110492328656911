import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";

const Root = () => {
  return (
    <div>
      <Header />
      <Navbar />
      <div style={{minHeight: "calc(100vh - 160px)"}}><Outlet /></div>
      <Footer />
    </div>
  )
}

export default Root;