import { useEffect, useRef, useState } from "react";

import { Config } from "../../config";
import { NavLinkButton } from "../form/Button";
import UilBars from "@iconscout/react-unicons/icons/uil-bars";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuDropdownRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("");
  const menuRef = useRef(null);
  
  const location = useLocation();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return (() => {
      document.removeEventListener('mousedown', handleClickOutside)
    });
  }, [menuRef]);

  useEffect(() => {
    setSelectedTab(location.pathname)
  }, [location.pathname])

  // Handler for click outside of account drop down
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)){
      setIsMenuOpen(false);
    }
  }

  // Handler for click on menu drop down
  const handleMenuDropDownClick = (event) => {
    event.preventDefault();
    setIsMenuOpen(!isMenuOpen)
  }

  const renderMenuDropDown = () => {
    return (
      <div ref={menuRef} className="absolute w-[200px] shadow border rounded bg-white z-50">
        <div className="mt-[1px]"><NavLinkButton isHorizontal={false} isSelected={selectedTab.startsWith("/home")} label="HOME" to="/home"/></div>
        <div className="mt-[1px]"><NavLinkButton isHorizontal={false} isSelected={selectedTab.startsWith("/services")} label="SERVICES" to="/services"/></div>
        <div className="mt-[1px]"><NavLinkButton isHorizontal={false} isSelected={selectedTab.startsWith("/gallery")} label="GALLERY" to="/gallery"/></div>
        <div className="mt-[1px]"><NavLinkButton isHorizontal={false} isSelected={selectedTab.startsWith("/blog")} label="BLOG" to="/blog"/></div>
        <div className="mt-[1px]"><NavLinkButton isHorizontal={false} isSelected={selectedTab.startsWith("/contactus")} label="CONTACT US" to="/contactus"/></div>
        <div className="mt-[1px]"><NavLinkButton isHorizontal={false} isSelected={selectedTab.startsWith("/aboutus")} label="ABOUT US" to="/aboutus"/></div>
      </div>
    );
  }

  if (Config.responsiveDetails.isWindowSmall) {
    return (
      <div className="h-[35px] text-purple-1 flex justify-between border-t-[5px] border-b-[2px] border-purple-1">
        <div className="cursor-pointer" ref={menuDropdownRef} onClick={handleMenuDropDownClick} >
          <NavLinkButton isSelected={isMenuOpen} label={<UilBars />} />

          {isMenuOpen && 
            renderMenuDropDown()
          }
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-[35px] min-w-[1000px] text-purple-1 flex justify-between border-t-[5px] border-b-[2px] border-purple-1">
        <div className="flex">
          <div className="ml-[1px]"><NavLinkButton isSelected={selectedTab.startsWith("/home")} label="HOME" to="/home"/></div>
          <div className="ml-[1px]"><NavLinkButton isSelected={selectedTab.startsWith("/services")} label="SERVICES" to="/services"/></div>
          <div className="ml-[1px]"><NavLinkButton isSelected={selectedTab.startsWith("/gallery")} label="GALLERY" to="/gallery"/></div>
          <div className="ml-[1px]"><NavLinkButton isSelected={selectedTab.startsWith("/blog")} label="BLOG" to="/blog"/></div>
          <div className="ml-[1px]"><NavLinkButton isSelected={selectedTab.startsWith("/contactus")} label="CONTACT US" to="/contactus"/></div>
          <div className="ml-[1px]"><NavLinkButton isSelected={selectedTab.startsWith("/aboutus")} label="ABOUT US" to="/aboutus"/></div>
        </div>
      </div>
    );
  }
  
}

export default Navbar;