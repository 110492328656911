const Soon = () => {
  return (
    <div className="ml-[20px] sm:ml-[50px] mt-[50px]">
      <div className="text-3xl font-bold text-purple-1">
        We're Coming Soon
      </div>
      <div className="text-red-1">
        We're working hard to finish the development of this site.
      </div>
    </div>
  )
}

export default Soon;