import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import BuildingImage from "../../images/building.JPG";
import { Config } from '../../config';
import Doctor11 from "../../images/doctor11.png";
import Doctor21 from "../../images/doctor21.png";

const AboutUs = () => {

  const renderQuotes = () => {
    let quotesSlides = Config.aboutUs.quotes.map((e, i) => {
      return (
        <SwiperSlide key={i}>
          <div className="flex justify-center ml-[10px]">
            &#x275D; {e.quote} &#x275E; {e.author ? `- ${e.author}` : ""}
          </div>
        </SwiperSlide>
      )
    })
    return (
      <div>
        <Swiper
          className="h-[150px] sm:h-[200px] sm:mt-[100px] sm:text-xl"
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
        >
          
          {quotesSlides}
        </Swiper>
      </div>
    )
  }

  return (
    <div className="m-[10px]">
      <div className="text-3xl text-purple-1">About us</div>
      <div className="flex justify-center">
        <div className="w-1/2 max-w-[300px]">
          <img src={BuildingImage} alt="Icon" />
        </div>
        <div className="w-1/2">
          <div className="m-[10px]  sm:text-3xl">{Config.aboutUs.header}</div>
          {renderQuotes()}
        </div>
      </div>
      <div className="my-[40px] h-[2px] w-full border border-purple-1"></div>
      {!Config.responsiveDetails.isWindowSmall && 
        <div>
          <div className="flex justify-center">
            <div className="w-1/2 max-w-[300px]">
              <div className="text-xl text-purple-1 mb-[40px]">Meet our doctors</div>
              <img src={Doctor11} alt="Icon" />
            </div>
            <div className="w-1/2 mt-[80px]">
              <div className="font-bold text-xl">{Config.aboutUs.doctor1.title}</div>
              <div className="font-bold">{Config.aboutUs.doctor1.subtitle}</div>
              <div className="text-justify">&#x275D; {Config.aboutUs.doctor1.summary} &#x275E;</div>
            </div>
          </div>
          <div className="mt-[50px] flex justify-center">
            <div className="w-1/2">
              <div className="font-bold text-xl">{Config.aboutUs.doctor2.title}</div>
              <div className="font-bold">{Config.aboutUs.doctor2.subtitle}</div>
              <div className="text-justify">&#x275D; {Config.aboutUs.doctor2.summary} &#x275E;</div>
            </div>
            <div className="w-1/2 max-w-[300px]">
              <img src={Doctor21} alt="Icon" />
            </div>
          </div>
        </div>
      }
      {Config.responsiveDetails.isWindowSmall && 
        <div>
          <div className="flex justify-center text-xl font-bold text-purple-1 mb-[40px]">Meet our doctors</div>
          <div className="flex justify-center"><img src={Doctor11} alt="Icon" /></div>
          <div className="flex justify-center mt-[40px]">
            <div>
              <div className="font-bold text-xl">{Config.aboutUs.doctor1.title}</div>
              <div className="font-bold">{Config.aboutUs.doctor1.subtitle}</div>
              <div className="text-justify">&#x275D; {Config.aboutUs.doctor1.summary} &#x275E;</div>
            </div>
          </div>
          <div className="flex justify-center"><img src={Doctor21} alt="Icon" /></div>
          <div className="flex justify-center mt-[40px]">
            <div>
              <div className="font-bold text-xl">{Config.aboutUs.doctor2.title}</div>
              <div className="font-bold">{Config.aboutUs.doctor2.subtitle}</div>
              <div className="text-justify">&#x275D; {Config.aboutUs.doctor2.summary} &#x275E;</div>
            </div>
          </div>
        </div>
      }
      
    </div>
  )
}

export default AboutUs;