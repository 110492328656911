import "./test.css";

import { motion, useInView } from "framer-motion"
import { useRef, useState } from "react";

import { Config } from "../../config";
import D1 from "../../images/laughinggas.jpg";
import D2 from "../../images/dentalcrown.jpeg";
import D3 from "../../images/preventivedentaltreatmnets.jpg";
import D4 from "../../images/rootcanal.jpg";
import D5 from "../../images/SpaceMaintainers.jpg";
import D6 from "../../images/Preventiveorthodontics.jpg";
import D7 from "../../images/dentalanesthesia.jpg";
import D8 from "../../images/Dentalspecialneeds.jpg";
import P1 from "../../images/pediatricOPD.JPG";
import P2 from "../../images/SpecialRoom.JPG";
import P3 from "../../images/PICU.JPG";
import P4 from "../../images/NICU.JPG";
import P5 from "../../images/Vaccine.jpg";
import P6 from "../../images/growth.jpg";
import P7 from "../../images/InhousePharmacy.JPG";

const Images = {
  pediatric: [P1, P2, P3, P4, P5, P6, P7],
  dental: [D1, D2, D3, D4, D5, D6, D7, D8]
}

const ServiceRenderBox = ({department, index, direction}) => {
  const titleRef = useRef(null);
  const imgRef = useRef(null);
  const summaryRef = useRef(null);
  const titleIsInView = useInView(titleRef, { once: true });
  const imgIsInView = useInView(imgRef, { once: true });
  const summaryIsInView = useInView(summaryRef, { once: true });
  const details = Config.services[department][index];

  const initX = direction === "left" ? "-500" : "500";

  return (
    <div className="flex justify-center mb-[80px]" >
      <div className="w-[300px]">
        {details.title === "Vaccination" && <div>&nbsp;</div>}
        <motion.div 
          initial={{ opacity: 0, y: 200 }}
          animate={titleIsInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.5}}
        >
          <div className="text-xl text-purple-1 text-center">{details.title}</div>
        </motion.div>
        <div ref={titleRef}></div>
        <motion.div 
          ref={imgRef}
          initial={{ opacity: 0, x: initX }}
          animate={imgIsInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1}}
        >
          <div><img src={Images[department][index]} alt="Icon" className="rounded"/></div>
        </motion.div>
        <motion.div 
          ref={summaryRef}
          initial={{ opacity: 0, x: initX }}
          animate={summaryIsInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <div>{details.summary}</div>
        </motion.div>
      </div>
    </div>
  );
}

const Services = () => {
  const [selectedDepartment, setSelectedDepartment] = useState("pediatric");
  
  const changeSelectedDepartment = (department) => {
    setSelectedDepartment(department);
  }

  const renderPediatricServices = () => {
    if (Config.responsiveDetails.isWindowSmall) {
      return (
        <div>
          <ServiceRenderBox department="pediatric" index={0} direction="left"/>
          <ServiceRenderBox department="pediatric" index={1} direction="right"/>
          <ServiceRenderBox department="pediatric" index={2} direction="left"/>
          <ServiceRenderBox department="pediatric" index={3} direction="right"/>
          <ServiceRenderBox department="pediatric" index={4} direction="left"/>
          <ServiceRenderBox department="pediatric" index={5} direction="right"/>
          <ServiceRenderBox department="pediatric" index={6} direction="left"/>
        </div>
      );
    } else {
      return (
        <div>
          <div className="flex justify-center">
          <div className="mr-[80px]"><ServiceRenderBox department="pediatric" index={0} direction="left"/></div>
          <div className="ml-[80px]"><ServiceRenderBox department="pediatric" index={1} direction="right"/></div>
          </div>
          <div className="flex justify-center">
          <div className="mr-[80px]"><ServiceRenderBox department="pediatric" index={2} direction="left"/></div>
          <div className="ml-[80px]"><ServiceRenderBox department="pediatric" index={3} direction="right"/></div>
          </div>
          <div className="flex justify-center">
          <div className="mr-[80px]"><ServiceRenderBox department="pediatric" index={4} direction="left"/></div>
          <div className="ml-[80px]"><ServiceRenderBox department="pediatric" index={5} direction="right"/></div>
          </div>
          <div className="flex justify-center">
          <div><ServiceRenderBox department="pediatric" index={6} direction="left"/></div>
          </div>
        </div>
      );
    }
  }

  const renderDentalServices = () => {
    if (Config.responsiveDetails.isWindowSmall) {
      return (
        <div>
          <ServiceRenderBox department="dental" index={0} direction="left"/>
          <ServiceRenderBox department="dental" index={1} direction="right"/>
          <ServiceRenderBox department="dental" index={2} direction="left"/>
          <ServiceRenderBox department="dental" index={3} direction="right"/>
          <ServiceRenderBox department="dental" index={4} direction="left"/>
          <ServiceRenderBox department="dental" index={5} direction="right"/>
          <ServiceRenderBox department="dental" index={6} direction="left"/>
          <ServiceRenderBox department="dental" index={7} direction="right"/>
        </div>
      );
    } else {
      return (
        <div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><ServiceRenderBox department="dental" index={0} direction="left"/></div>
            <div className="ml-[40px]"><ServiceRenderBox department="dental" index={1} direction="right"/></div>
          </div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><ServiceRenderBox department="dental" index={2} direction="left"/></div>
            <div className="ml-[40px]"><ServiceRenderBox department="dental" index={3} direction="right"/></div>
          </div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><ServiceRenderBox department="dental" index={4} direction="left"/></div>
            <div className="ml-[40px]"><ServiceRenderBox department="dental" index={5} direction="right"/></div>
          </div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><ServiceRenderBox department="dental" index={6} direction="left"/></div>
            <div className="ml-[40px]"><ServiceRenderBox department="dental" index={7} direction="right"/></div>
          </div>
        </div>
      );
    }
  }

  const renderToggle = () => {
    if (selectedDepartment === "pediatric") {
      return (
        <div className="flex justify-center mb-[40px]">
          <div className="flex justify-center rounded-full border-4 border-purple-1 cursor-pointer">
            <div className="bg-purple-1 rounded-full px-[10px] text-white text-center">Pediatric Services</div>
            <div className="bg-white-1 px-[7px] text-center text-purple-1" onClick={changeSelectedDepartment.bind(null, "dental")}>
              Pediatric Dental Services
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center mb-[40px]">
        <div className="flex justify-center rounded-full border-4 border-purple-1 cursor-pointer">
          <div className="bg-white-1 px-[7px] text-center text-purple-1" onClick={changeSelectedDepartment.bind(null, "pediatric")}>
            Pediatric Services
          </div>
          <div className="bg-purple-1 rounded-full px-[10px] text-white text-center">Pediatric Dental Services</div>
        </div>
      </div>
      );
    }
  }

  return (
    <div className="m-[10px]">
      <div className="text-3xl text-purple-1 mb-[40px]">Services</div>
      {renderToggle()}
      {selectedDepartment === "pediatric" && renderPediatricServices()}
      {selectedDepartment === "dental" && renderDentalServices()}
    </div>
  )
}

export default Services;