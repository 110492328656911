// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import BabyI1 from "../../images/babyimage4.png";
import { Config } from "../../config";
import Home1 from "../../images/home1.png";
import SI1 from "../../images/building.JPG";
import SI2 from "../../images/reception.JPG";
import SI3 from "../../images/pediatricOPD.JPG";
import SI4 from "../../images/Dental.JPG";
import SI5 from "../../images/generalward.JPG";
import SI6 from "../../images/SpecialRoom.JPG";
import SI7 from "../../images/PICU.JPG";
import SI8 from "../../images/NICU.JPG";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import UilMapMarker from "@iconscout/react-unicons/icons/uil-map-marker";
import { motion } from "framer-motion"

const Home = () => {

  const renderMoto = () => {
    return (
      <div className="mt-[20px] w-full text-center text-red-1 italic text-xl sm:text-2xl font-semibold">
        <motion.div animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}>
            <div>
              <div>{Config.nameDetails.motoTitle1}</div>
              <div>{Config.nameDetails.motoTitle2}</div>
            </div>
        </motion.div>
      </div>
    );
  }

  const renderSlideShow = () => {
    const imageWidth = "100%";
    const imageHeight = "100%";
    return (
      <div className="mt-[40px] mb-[40px] w-full flex justify-center">
        <Swiper 
          className="w-full max-w-[800px] h-[300px] sm:h-[600px]"
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
        >
          <SwiperSlide>
            <div className="w-full h-full flex justify-center">
              <img src={SI1} alt="Icon" style={{width:imageWidth, height:imageHeight}} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full h-full flex justify-center">
              <img src={SI2} alt="Icon" style={{width:imageWidth, height:imageHeight}}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full h-full flex justify-center">
              <img src={SI3} alt="Icon" style={{width:imageWidth, height:imageHeight}}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full h-full flex justify-center">
              <img src={SI4} alt="Icon" style={{width:imageWidth, height:imageHeight}}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full h-full flex justify-center">
              <img src={SI5} alt="Icon" style={{width:imageWidth, height:imageHeight}}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full h-full flex justify-center">
              <img src={SI6} alt="Icon" style={{width:imageWidth, height:imageHeight}}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full h-full flex justify-center">
              <img src={SI7} alt="Icon" style={{width:imageWidth, height:imageHeight}}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full h-full flex justify-center">
              <img src={SI8} alt="Icon" style={{width:imageWidth, height:imageHeight}}/>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }

  const renderDesign1 = () => {
    return (
      <div className="w-100vw h-[100px] ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
          <path fill="#e95862" fillOpacity="1" style={{transformBox: "fill-box"}} 
            d="M 1225 25 L 1365 25 L 1440 100 L 1300 100 Z"></path>
          <path fill="#465580" fillOpacity="1" style={{transformBox: "fill-box"}} 
            d="M 0 0 L 1200 0 L 1250 50 L 0 50 Z"></path>
          </svg>
      </div>
    )
  }

  const renderIntroduction = () => {
    return (
      <div className="flex justify-center mr-[30px]">
        <div className="sm:w-1/2 sm:ml-[100px] ml-[30px] sm:mt-[120px] sm:text-2xl text-xs text-justify">
          <p className="indent-8">{Config.nameDetails.intro1}</p>
        </div>
        <div className="min-w-[150px] sm:h-[400px] h-[240px] ml-[30px] sm:ml-[200px]">
          <img src={BabyI1} alt="Icon" style={{width:"100%", height:"100%"}}/>
        </div>
      </div>
    )
  }

  const renderPediaAppointmentLabel = () => {
    return (
      <div className="w-[350px] mt-[0px] bg-purple-1 py-[10px] text-center text-white rounded-full">
        <div >
          <div className="text-xl mt-[5px]">
            {Config.doctorDetails.pediatric.name}
          </div>
          <div>
            {Config.doctorDetails.pediatric.degree}
          </div>
          <div>
            {Config.doctorDetails.pediatric.title}
          </div>
          <div className="underline">
            <a target="_blank" rel="noreferrer" href="https://book.solomo360.com/Arth%20Children%20Hospital%20and%20Child%20Dental%20Care">BOOK APPOINTMENT</a>
          </div>
        </div>
      </div>
    )
  }

  const renderDentalAppointmentLabel = () => {
    return (
      <div className="w-[350px] mt-[0px]  bg-red-1 py-[10px] text-center rounded-full">
        <div className="mt-[5px]">
          <div className="text-xl">
            {Config.doctorDetails.dental.name}
          </div>
          <div>
            {Config.doctorDetails.dental.degree}
          </div>
          <div>
            {Config.doctorDetails.dental.title}
          </div>
          <div className="underline">
            <a target="_blank" rel="noreferrer" href="https://book.solomo360.com/Arth%20Children%20Hospital%20and%20Child%20Dental%20Care">BOOK APPOINTMENT</a>
          </div>
        </div>
      </div>
    )
  }

  const renderAppointmentSection = () => {
    if (Config.responsiveDetails.isWindowSmall) {
      return (
        <div className="mt-[50px] w-full">
          <div className="flex w-full justify-center">
            {renderPediaAppointmentLabel()}
          </div>
          <div className="flex w-full justify-center mt-[50px]">
            {renderDentalAppointmentLabel()}
          </div>
        </div>
      )
    } else {
      return (
        <div className="mt-[50px] flex justify-center min-w-[1000px]">
          <div className="mr-[20px]">{renderPediaAppointmentLabel()}</div>
          <div className="ml-[20px]">{renderDentalAppointmentLabel()}</div>
        </div>
      )
    }
  }

  const renderAddressSection = () => {
    if (Config.responsiveDetails.isWindowSmall) {
      return (
        <div>
          <div className="flex justify-between mt-[40px] mr-[40px]">
            <img src={Home1} alt="Icon" className="sm:h-[300px] h-[200px]"/>
            <div className="w-full text-xs sm:text-base">
              <div className="flex mt-[20px]">
                <UilClock />
                <div className="">
                  <div className="sm:text-xl text-lg">Consulting Hours</div>
                  <div>OPD Timings:</div>
                  <div className="flex">
                    <div className="w-[75px]">Mon - Sat:</div>
                    <div>
                      <div>{Config.contactDetails.time1}</div>
                      <div>&</div>
                      <div>{Config.contactDetails.time2}</div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-[75px]">Sun:</div>
                    <div>
                      <div>{Config.contactDetails.time3}</div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-[75px]">Emergency 24x7:</div>
                    <a href={`tel:${Config.contactDetails.phone}`} target="_blank" rel="noreferrer">
                      <div className="text-blue-link">Call {Config.contactDetails.phone}</div>
                      </a>
                  </div>
                </div>
              </div>
              <div className="flex mt-[20px]">
                <UilMapMarker />
                <div>
                  <div className="sm:text-xl text-lg">Address</div>
                  <div>{Config.contactDetails.address1}</div>
                  <div>{Config.contactDetails.address2}</div>
                  <div>{Config.contactDetails.address3}</div>
                  <div>{Config.contactDetails.address4}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center w-full mt-[30px] mb-[30px]">
            <iframe title="address-googlemap" src={Config.contactDetails.googleAddress}  
              width="300" height="300" 
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-between mt-[50px] mr-[100px]">
          <img src={Home1} alt="Icon" className="sm:h-[300px] h-[200px]"/>
          <div className="flex text-xl">
            <UilClock  className="mt-[5px]"/>
            <div className="pl-[2px]">
              <div className="text-3xl">Consulting Hours</div>
              <div>OPD Timings:</div>
              <div className="flex">
                <div className="w-[170px]">Mon - Sat:</div>
                <div>
                  <div>{Config.contactDetails.time1}</div>
                  <div>&</div>
                  <div>{Config.contactDetails.time2}</div>
                </div>
              </div>
              <div className="flex">
                <div className="w-[170px]">Sun:</div>
                <div>
                  <div>{Config.contactDetails.time3}</div>
                </div>
              </div>
              <div className="flex">
                <div className="w-[170px]">Emergency 24x7:</div>
                <a href={`tel:${Config.contactDetails.phone}`} target="_blank" rel="noreferrer">
                  <div className="text-blue-link">Call {Config.contactDetails.phone}</div>
                </a>
              </div>
            </div>
          </div>
          <div className="flex text-xl ">
            <UilMapMarker className="mt-[5px]"/>
            <div className="pl-[2px]">
              <div className="sm:text-3xl">Address</div>
              <div>{Config.contactDetails.address1}</div>
              <div>{Config.contactDetails.address2}</div>
              <div>{Config.contactDetails.address3}</div>
              <div>{Config.contactDetails.address4}</div>
            </div>
          </div>
          <div>
            <iframe title="address-googlemap" src={Config.contactDetails.googleAddress}   
              width="400" height="400" 
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      );
    }
    
  }

  return (
    <div className="">
      {renderMoto()}
      {renderSlideShow()}
      {renderDesign1()}
      {renderIntroduction()}
      {renderAppointmentSection()}
      {renderAddressSection()}
    </div>
  )
}

export default Home;


