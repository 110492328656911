import { Config } from "../../config";
import FBIcon from "../../images/fb.png";
import InstagramIcon from "../../images/instagram.png";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilMap from "@iconscout/react-unicons/icons/uil-map";
import UilPhoneVolume from "@iconscout/react-unicons/icons/uil-phone-volume";
import WhatsappIcon from "../../images/whatsapp.png";

const ContactUs = () => {
  
  const renderLocation = () => {
    return (
      <div className="flex bg-gray-2 sm:bg-white rounded p-[10px] mt-[20px] sm:w-[500px]">
        <div className="mt-[15px]">
          <a href={`${Config.contactDetails.googleMapLink}`} target="_blank" rel="noreferrer">
            <div className="relative">
              <UilMap color="#465580" height="50px" width="50px"/>
            </div>
          </a>
        </div>
        <div className="ml-[20px]">
          <div className="text-xl">Arth Children Hospital & Child Dental Care</div>
          <div>{Config.contactDetails.fullAddress}</div>
        </div>
      </div>
    );
  }

  const renderCall = () => {
    return (
      <div className="flex border-gray-2 border-2 sm:border-0 rounded p-[10px] mt-[20px] sm:w-[500px]">
        <div>
          <a href={`tel:${Config.contactDetails.phone}`} target="_blank" rel="noreferrer"><UilPhoneVolume color="#465580" height="50px" width="50px"/></a>
        </div>
        <div className="ml-[20px]">
          <div className="text-xl">Call Today</div>
          <a href={`tel:${Config.contactDetails.phone}`} target="_blank" rel="noreferrer" className="text-blue-link">{Config.contactDetails.phone}</a>
        </div>
      </div>
    );
  }

  const renderWhatsapp = () => {
    return (
      <div className="flex bg-gray-2 sm:bg-white rounded p-[10px] mt-[20px] sm:w-[500px]">
        <div className="cursor-pointer">
          <a href={`https://api.whatsapp.com/send?phone=${Config.contactDetails.whatsapp}`} target="_blank" rel="noreferrer"><img src={WhatsappIcon} alt="Icon" width={50} height={50}/></a>
        </div>
        <div className="ml-[20px]">
          <div className="text-xl">Whatsapp Today</div>
          <a href={`https://api.whatsapp.com/send?phone=${Config.contactDetails.whatsapp}`} target="_blank" rel="noreferrer" className="text-blue-link">{Config.contactDetails.whatsapp}</a>
        </div>
      </div>
    );
  }

  const renderMail = () => {
    return (
      <div className="flex border-gray-2 border-2 sm:border-0 rounded p-[10px] mt-[20px] sm:w-[500px]">
        <div>
          <a href={`mailto:${Config.contactDetails.email}`} target="_blank" rel="noreferrer"><UilEnvelope color="#465580" height="50px" width="50px"/></a>
        </div>
        <div className="ml-[20px]">
          <div className="text-xl">Email Us</div>
          <a href={`mailto:${Config.contactDetails.email}`} target="_blank" rel="noreferrer" className="text-blue-link">{Config.contactDetails.email}</a>
        </div>
      </div>
    );
  }

  const renderInstagram = () => {
    return (
      <div className="flex bg-gray-2 sm:bg-white rounded p-[10px] mt-[20px] sm:w-[500px]">
        <div className="cursor-pointer">
          <a href={`https://www.instagram.com/${Config.contactDetails.instagramUsername}`} target="_blank" rel="noreferrer"><img src={InstagramIcon} alt="Icon" width={50} height={50}/></a>
        </div>
        <div className="ml-[20px]">
          <div className="text-xl">Check us on Instagram</div>
          <a href={`https://www.instagram.com/${Config.contactDetails.instagramUsername}`} target="_blank" rel="noreferrer" className="text-blue-link">{Config.contactDetails.instagramHandle}</a>
        </div>
      </div>
    );
  }

  const renderFacebook = () => {
    return (
      <div className="flex border-gray-2 border-2 sm:border-0 rounded p-[10px] mt-[20px] sm:w-[500px]">
        <div className="cursor-pointer">
          <a href={Config.contactDetails.fbAddress} target="_blank" rel="noreferrer"><img src={FBIcon} alt="Icon" width={50} height={50}/></a>
        </div>
        <div className="ml-[20px]">
          <div className="text-xl">Check us on Facebook</div>
          <a href={Config.contactDetails.fbAddress} target="_blank" rel="noreferrer" className="text-blue-link">{Config.contactDetails.fbHandle}</a>
        </div>
      </div>
    );
  }

  return (
    <div className="m-[10px]">
      <div className="text-3xl text-purple-1 mb-[30px]">Contact us</div>
      {Config.responsiveDetails.isWindowSmall && 
        <div>
          {renderLocation()}
          {renderCall()}
          {renderWhatsapp()}
          {renderMail()}
          {renderInstagram()}
          {renderFacebook()}
        </div>
      }
      {!Config.responsiveDetails.isWindowSmall && 
        <div>
          <div className="flex justify-between">
            {renderLocation()}
            {renderCall()}
            {renderWhatsapp()}
          </div>
          <div className="flex justify-between">
            {renderMail()}
            {renderInstagram()}
            {renderFacebook()}
          </div>
        </div>
      }
    </div>
  )
}

export default ContactUs;