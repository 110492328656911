import { Config } from "../../config";
import { useParams } from "react-router-dom";

const BlogViewer = () => {
  
  const { section, index } = useParams();

  return (
      <div className="flex justify-center w-full">
        <iframe
          title={Config.blogs[section][index].title}
          src={Config.blogs[section][index].link}
          width={window.innerWidth}
          height={window.innerHeight}
          className="w-[1000px]"
        />
      </div>
  )
}

export default BlogViewer;