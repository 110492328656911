export const Config = {
  server: {
    url: "http://localhost:8001/api",
    authPaths: [
      "/profile",
    ]
  },
  strongPasswordParams: {
    minLength: 10,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1, 
    minSymbols: 1
  },
  responsiveDetails: {
    innerWidthThreshold: 640,
    isWindowSmall: window.innerWidth <= 640
  },
  nameDetails: {
    title: "ARTH",
    subTitle: "Children Hospital & Child Dental Care",
    motoTitle1: "Where little hearts thrive and tiny teeth sparkle.",
    motoTitle2: "Pediatric Hospital & Dental Harmony.",
    intro1: "Welcome to ARTH Children hospital, we provide specialized healthcare services for your child’s well being and good oral health. Children require extra attention to health in order to enjoy best possible childhood, hence our dedicated team of doctors focus on well-being of children from birth to adolescence. We provide Consultation, Vaccination, NICU, PICU and Pediatric Dental department under one roof.",
  },
  contactDetails: {
    time1: "10:00am to 1:00pm",
    time2: "6:00am to 9:00pm",
    time3: "10:00am to 1:00pm",
    phone: "+91-6355707395",
    whatsapp: "916355707395",
    email: "arthchildcare@gmail.com",
    instagramHandle: "@arthchildcare",
    instagramUsername: "arthchildcare",
    fbHandle: "@ArthChildrenHospitalandChildDentalCare",
    fbAddress: "https://www.facebook.com/ArthChildrenHospitalandChildDentalCare",
    address1: "B 512-A, Sun South Trade,",
    address2: "Opp. Bopal Police station,",
    address3: "Gala Gymkhana Road,",
    address4: "South Bopal, Ahmedabad-58",
    fullAddress: "B 512-A, Sun South Trade, Opp. Bopal Police station, Gala Gymkhana Road, South Bopal, Ahmedabad-58",
    googleMapLink: "https://maps.app.goo.gl/AmAve89ZkmoDcMFq8",
    googleAddress: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.988319691433!2d72.46764667573972!3d23.024201079172705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9be05394a707%3A0x442af62d6cc66c7e!2sArth%20Children%20Hospital%20%26%20Child%20Dental%20Care!5e0!3m2!1sen!2sus!4v1706464736441!5m2!1sen!2sus"
    
  },
  appointments: {
    dentalInterval: 15,
    pediatricsInterval: 10
  },
  doctorDetails: {
    dental: {
      name: "Dr. Khyati Patel",
      degree: "MDS (Pedodontics)",
      title: "Pediatric & Preventive Dentist"
    },
    pediatric: {
      name: "Dr. Rishi Patel",
      degree: "MD (Paed.)",
      title: "Consultation & Neonatologist"
    }
  },
  aboutUs: {
    header: "Pediatric Wellness & Dental Joy - at the same place to craft brighter childhood.",
    quotes: [
      {quote: "A child’s health is the most valuable thing that a parent can nurture.", author: "dick Cheney"},
      {quote: "A sound body and mind in a child are the pillars of their growth and success.", author: ""},
      {quote: "The Health of our children is reflection of well-being of our society.", author: "Walter Cronkite"}
    ],
    doctor1: {
      title: "Dr. Rishi J Patel (MD Paed.)",
      subtitle: "Consulting Pediatrician & Neonatologist",
      summary: "With expertise in pediatric intensive care and neonatology, I am dedicated to fostering the health and well-being of children. I strongly believe that the best way to help kids grow up healthy is by preventing health problems before they even start. Prevention is like our superhero strategy, especially for little ones who have a lot of growing and living ahead of them. Let's make sure they have a bright and healthy future!"
    },
    doctor2: {
      title: "Dr. Khyati R Patel (BDS, MDS)",
      subtitle: "Consulting Pediatric & Preventive Dentist",
      summary: "Hello! I have expertise in treating dental health issues in children as they require special care and behaviour management during dental procedure. Many times, people overlook the importance of oral health in children and babies. But it’s crucial because it lays the groundwork for the teeth they’ll have for their whole lives. I’m passionate about spreading awareness and educating families about how vital it is to take care of their children’s oral health."
    }
  },
  services: {
    pediatric: [
      {
        title: "Consultation/OPD (Outdoor Patient Department)",
        summary: "Facility of Consultation is to deal with problems that might not require hospitalization. We try to provide child friendly environment in OPD. We also take opportunity to provide other necessary assessments and guidance like nutrition, Growth &amp; Development, do’s and don’ts, vaccination during routine OPD visits."
      },
      {
        title: "Indoor Facility/Hospitalization Facility",
        summary: "We have child friendly rooms for children requiring hospitalization."
      },
      {
        title: "PICU",
        summary: "We have facility of Pediatric ICU (Intensive Care Unit) to manage critically ill patients."
      },
      {
        title: "NICU",
        summary: "Neonatal ICU (Intensive Care Unit) is also available at our center. Those born prematurely, low birth weight, birth asphyxia, birth defect or sick infant below 1 month of age requiring hospitalization are admitted in NICU. We provide special care to these small babies in our NICU. We have advanced instruments and highly trained 24x7 nursing staff for NICU."
      },
      {
        title: "Vaccination",
        summary: "Children’s immune system is developing and are very prone to infectious diseases. For most of the infectious diseases vaccines are available to prevent them. We have all the available vaccine at our center, that are provided with proper guidance. We keep our vaccine schedule updated according to latest researches and guidelines."
      },
      {
        title: "Growth & Developmental assessment",
        summary: "Childhood is the age of mental and physical growth. Regular assessment of which is crucial during the first 5 years of age. We do necessary assessment and provide necessary guidance regarding the same during every visit, including nutrition."
      },
      {
        title: "In-house pharmacy",
        summary: "We also have inhouse pharmacy to provide necessary medicines at the same center."
      }
    ],
    dental: [
      {
        title: "Painless dental treatment under laughing gas",
        summary: "All Dental treatments are available under laughing gas conscious sedation at our center. This technique helps to reduce child’s anxiety and makes Dental treatments painless and fearless. It also allows the child to tolerate unpleasant procedure by reducing or relieving anxiety, discomfort or pain."
      },
      {
        title: "Tooth colored and stainless-steel dental crowns",
        summary: "Cavities have always been a common problem for children, but today there are more restoration options than ever. One of the most popular pediatric dentistry treatments is tooth-colored crowns for front teeth and molars and highly durable stainless-steel crowns for molars."
      },
      {
        title: "Preventive Dental Treatments",
        summary: "Fluorides and Sealants are preventive treatment modalities available for children. Fluoride application twice a year reduces the incidence of caries by 37% in milk teeth and 43% in permanent teeth. Sometimes Natural teeth has deep grooves and pits which can cause frequent food lodgment that might lead to cavities. Sealants are used to fill those pits and grooves to make teeth self-cleansable."
      },
      {
        title: "Root canal treatment (Pulpectomy) in Milk tooth",
        summary: "A root canal treatment (endodontic therapy) is a dental treatment for infections in tooth pulp, the innermost vital layer of your teeth. When infection reaches to tooth pulp, it needs to be clean and disinfected. If left untreated infection can reach to underlying bone carrying the permanent tooth."
      },
      {
        title: "Removal of Milk tooth and Space maintainers",
        summary: "Removal of milk tooth becomes necessary when tooth destruction is severe and tooth is not salvageable. When tooth removal is done before the time of natural exfoliation, Space maintainers are given to preserve space for succedaneous permanent teeth."
      },
      {
        title: "Preventive Orthodontics",
        summary: "Failure to seek basic oral care in the primary dentition is the principal cause of malocclusion in the permanent dentition. We provide treatment modalities at early age which can reduce severity or prevent malalignment of teeth."
      },
      {
        title: "Dental treatment under general anesthesia",
        summary: "When there is extensive multiple teeth decay present in early childhood or child is not able to cooperate on dental chair due to age or other factors, treatments are done under general anesthesia. In general anesthesia entire necessary dental treatment is performed in single sitting."
      },
      {
        title: "Dental treatments in children with special needs",
        summary: "Children with cognitive, developmental, emotional, physical or medical conditions may experience heightened anxiety or discomfort when it comes to dental care (both at home and at the dentist). Handling their unique needs requires patience, understanding and compassion.  As pediatric dentists, we are specially trained in different behavioral techniques to help your children feel as comfortable with dentist as possible."
      }
    ]
  },
  blogs: {
    nutrition: [
      {
        title: "Nutrition for 1-2 years IAP guidelines",
        link: "https://docs.google.com/document/d/e/2PACX-1vSUM9b4dHAY8RTo133UzVhN8Qo10vmiuU3Wj6rT0Dm0hP-WYwtNxB1VTHWAaUn6bC93bnfSDCLKkAaA/pub?embedded=true"
      },
      {
        title: "Nutrition in 1-2 years Gujarati",
        link: "https://docs.google.com/document/d/e/2PACX-1vSI5TD5jFUDEawpZP2T7dsJqovimlB272OGY-1NHRldOrgSEyfdY84tGeRSlWQRl8fH9meCkURGdogv/pub?embedded=true"
      },
      {
        title: "Nutrition for preschoolers 2-5 years IAP guidelines",
        link: "https://docs.google.com/document/d/e/2PACX-1vR-KeJ86ENF12HvPxdgfc0myq6Lby_mOQ-qXLC9TYsTOBuNZ2m3z4GPTb0Xi6VnrRIEzNs8sasFR056/pub?embedded=true"
      },
      {
        title: "Nutrition for preschoolers 2-5 years Gujarati",
        link: "https://docs.google.com/document/d/e/2PACX-1vSscQOTMDWcRo2KieE7Q_GzrNvYEnYZTawiaonHZ9ymzinZxviULLWdsaA1mCEDCTm98lpeuw4jszqV/pub?embedded=true"
      },
      {
        title: "Nutrition 5-12 years IAP guidelines",
        link: "https://docs.google.com/document/d/e/2PACX-1vRnnVx0ba1ge0YdpZfmgSkzfA6yl4JeKiQMNjzI55IoapLm3j7C1IZNZffHPMWqIdy4QJ85wIM7rtbQ/pub?embedded=true"
      },
      {
        title: "Nutrition 5-12 years Gujarati",
        link: "https://docs.google.com/document/d/e/2PACX-1vQYjOAWfcnP0NMp-KcZbBOUY0layooxR3dbyHKpgyKJBMZEduqywLZbuodCDmYpkMw6ET63LCLGvs3E/pub?embedded=true"
      },
      {
        title: "Nutrition for adolescents 10-19 years IAP guidelines",
        link: "https://docs.google.com/document/d/e/2PACX-1vSwCi0jdQ3N38svljIND9oQ7D5L3b5r4b4xSME2E68Eyz5Bu-Yyr-lm02zEQqI-HBA20hYdT2gTxy5K/pub?embedded=true"
      },
      {
        title: "Nutrition for adolescents 10-19 years Gujarati",
        link: "https://docs.google.com/document/d/e/2PACX-1vQ80BL3OxhA8_ahVj5TBE9nrgKx1i8TrmjaRVqw7nchOSGB56R4YlH8L4ryik1kZk0jUDKD6wK9n3Eb/pub?embedded=true"
      },
      {
        title: "Vitamins and minerals IAP guidelines",
        link: "https://docs.google.com/document/d/e/2PACX-1vT9rmw4x36qS4HRvoaHo21OVE_OhqaKkK3hYKIMoKN2DYXSABZJwLvJ3VSaAToBGPTIRQIAyH27xkOZ/pub?embedded=true"
      },
      {
        title: "Vitamins and minerals Gujarati",
        link: "https://docs.google.com/document/d/e/2PACX-1vRtu-vfPRSeVs1tS5FTPymUCVTsmmEkjM9GmUQxfu_9IqXmVGzpXBFiMU_769QR9B7Sr5j0urZK6T93/pub?embedded=true"
      },
      {
        title: "Breastfeeding IAP guidelines",
        link: "https://docs.google.com/document/d/e/2PACX-1vR8NpE8p4W4Yy5Bsy9dMT04EbuNtqqUvCxNWjKMayaU3jGeLFDEqV3muuI1FLf34X2dOQjZu8181DLQ/pub?embedded=true"
      },
      {
        title: "Breastfeeding Gujarati",
        link: "https://docs.google.com/document/d/e/2PACX-1vQCmVtUK1oxhKHwOc-aqOYQZp2tKS6ZC-yYEnN6YSGxK3IKfgbYP4UAqZgfQ3VgUT8X_G96j9-gYSu9/pub?embedded=true"
      },
      {
        title: "Complementary feeding IAP guidelines",
        link: "https://docs.google.com/document/d/e/2PACX-1vRAALIw5sfh_GHc536bl5tsxvFoWQFJ5vFA50mkpX8FmFLRI9YUR899j8A146Fh_WHUZDi8j5WbCgs9/pub?embedded=true"
      },
    ],
    general: [
      {
        title: "Toys-Plays-Sports IAP guidelines",
        link: "https://docs.google.com/document/d/e/2PACX-1vSESDzYVDZAbfBh63Su7V9R3Z6N-a0hD-bBXFsUD0vanbe3wiVQaeI73TTaEMzTL_o8GK7Tzp4hezOj/pub?embedded=true"
      },
      {
        title: "Toys-Plays-Sports Gujarati",
        link: "https://docs.google.com/document/d/e/2PACX-1vQ0h4OqGgm3Ol-ebIh-gK2DaWDPxqzyoEcqCI0tYqXHt2rD1Mbs4T2b_oaKDsT7Tcn95DJ70XEaJQO6/pub?embedded=true"
      },
    ]
  }
}