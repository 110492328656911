import { NavLink } from "react-router-dom"

export function FormButton({label, ...otherProps}) {
  let className = "border rounded text-white h-8 px-3";
  if (otherProps.loading) {
    className += " inline-flex items-center";
  }
  if (otherProps.loading || otherProps.disabled) {
    className += " bg-gray-1 border-gray-1 cursor-not-allowed"
  } else {
    className += " bg-purple-1 border-purple-1 hover:bg-white hover:text-purple-1 active:bg-white active:text-purple-1"
  }
  return (
    <button
      className={className}
      {...otherProps}
    >
      {otherProps.loading && 
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-1" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      }
      {label}
    </button>
  )
}

export function FormButtonRed({label, ...otherProps}) {
  let className = "border rounded text-white h-8 px-3";
  if (otherProps.loading) {
    className += " inline-flex items-center";
  }
  if (otherProps.loading || otherProps.disabled) {
    className += " bg-gray-1 border-gray-1 cursor-not-allowed"
  } else {
    className += " bg-red-error border-red-error hover:bg-white hover:text-red-error active:bg-white active:text-red-error"
  }
  return (
    <button
      className={className}
      {...otherProps}
    >
      {otherProps.loading && 
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-red-error" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      }
      {label}
    </button>
  )
}

export function NavLinkButton({label, isSelected, isHorizontal=true, ...otherProps}) {
  let className = "h-full hover:text-white hover:bg-purple-1 active:text-white active:bg-purple-1 px-[15px]";
  if (isSelected) {
    className += " text-white bg-purple-1";
  } else {
    className += " text-purple-1 bg:white";
  }
  if (!isHorizontal) {
    className += " py-[15px]";
  }
  return (
    <div className={className}>
      <NavLink
        {...otherProps}
      >
        {label}
      </NavLink>
    </div>
  )
}

export function QuickLinkButton({label, ...otherProps}) {
  return (
    <div className="h-[20px] hover:text-blue-link hover:underline hover:cursor-pointer">
      <NavLink
        {...otherProps}
      >
        {label}
      </NavLink>
    </div>
  )
}

export function SocialLinkButton({src, ...otherProps}) {
  return (
    <div className="h-[20px] hover:cursor-pointer">
      <img src={src} alt="Icon" width={20} height={20} {...otherProps}/>
    </div>
  )
}