import { Route, Routes } from "react-router-dom";

import AboutUs from "./components/home/AboutUs";
import Blog from "./components/home/Blog";
import BlogViewer from "./components/home/BlogViewer";
import ContactUs from "./components/home/ContactUs";
import Gallery from "./components/home/Gallery";
import Home from "./components/home/Home";
import Root from "./components/home/Root";
import Services from "./components/home/Services";
import Testimonials from "./components/home/Testimonials";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="services" element={<Services />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:section/:index" element={<BlogViewer />} />
        <Route path="contactus" element={<ContactUs />} />
        <Route path="testimonials" element={<Testimonials />} />
      </Route>
    </Routes>
  );
}

export default App;
