import { QuickLinkButton, SocialLinkButton } from "../form/Button";

import { Config } from "../../config";
import FBIcon from "../../images/fb.png";
import InstagramIcon from "../../images/instagram.png";
import UilMapMarker from "@iconscout/react-unicons/icons/uil-map-marker";
import UilPhoneVolume from "@iconscout/react-unicons/icons/uil-phone-volume";
import WhatsappIcon from "../../images/whatsapp.png";

const Footer = () => {
  return (
    <div className="h-[100px] text-white ">
      <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 150">
        <path fill="#465580" style={{transformBox: "fill-box"}} fillOpacity="1" 
          d="M 0 50 L 0 150 L 1440 150 L 1440 100 C 1300 100 700 50 700 50 C 400 20 100 0 0 50 Z">
        </path>
        <path fill="#e95862" style={{transformBox: "fill-box"}} fillOpacity="1" 
          d="M 0 100 L 0 150 L 1440 150 L 1440 150 C 1300 150 700 100 700 100 C 400 70 100 50 0 100 Z">
        </path>
      </svg>
      <div className="bg-red-1 border-red-1 h-[150px] w-full ">
        <div className="flex justify-center w-full sm:pl-[80px]">
          <div>
            <div className="text-center">Quick Links</div>
            <div className="sm:flex sm:justify-between text-center">
              <div>
                <div className="mt-[15px]"><QuickLinkButton label="HOME" to="/home"/></div>
                <div className="mt-[15px]"><QuickLinkButton label="SERVICES" to="/services"/></div>
              </div>
              <div className="sm:ml-[20px]">
                <div className="mt-[15px]"><QuickLinkButton label="GALLERY" to="/gallery"/></div>
                <div className="mt-[15px]"><QuickLinkButton label="ABOUT US" to="/aboutus"/></div>
              </div>
            </div>
          </div>
          <div className="sm:ml-[100px] ml-[20px]">
            <div className="text-center">Social Links</div>
            <div className="flex justify-center ">
                <div>
                  <div className="mt-[15px]"><a href={`https://www.instagram.com/${Config.contactDetails.instagramUsername}`} target="_blank" rel="noreferrer"><SocialLinkButton src={InstagramIcon}/></a></div>
                  <div className="mt-[15px]"><a href={Config.contactDetails.fbAddress} target="_blank" rel="noreferrer"><SocialLinkButton src={FBIcon}/></a></div>
                  <div className="mt-[15px]"><a href={`https://api.whatsapp.com/send?phone=${Config.contactDetails.whatsapp}`} target="_blank" rel="noreferrer"><SocialLinkButton src={WhatsappIcon}/></a></div>
                </div>
            </div>
          </div>
          <div className="sm:ml-[100px]  ml-[20px] text-center">
            <div className="text-center">Contact Links</div>
            <a href={`${Config.contactDetails.googleMapLink}`} target="_blank" rel="noreferrer">
              <div className="flex justify-center text-center mt-[15px] sm:w-[420px]"><UilMapMarker height="30px" width="30px" />{!Config.responsiveDetails.isWindowSmall && Config.contactDetails.fullAddress}</div>
            </a>
            <a href={`tel:${Config.contactDetails.phone}`} target="_blank" rel="noreferrer">
              <div className="flex justify-center text-center mt-[15px]"><UilPhoneVolume />{!Config.responsiveDetails.isWindowSmall && Config.contactDetails.phone}</div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-red-1 border-red-1 h-[70px] w-full justify-end ">
        <div className="px-[20px] pb-[20px] mt-[30px] text-white text-xs inline-block align-baseline">
          &copy; Copyright 2023, Arth Children Hospital. All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;