import "./test.css";

import { motion, useInView } from "framer-motion"

import { Config } from "../../config";
import { NavLink } from "react-router-dom";
import { useRef } from "react";

const BlogBox = ({section, index}) => {
  const titleRef = useRef(null);
  const titleIsInView = useInView(titleRef, { once: true });
  const details = Config.blogs[section][index];

  return (
    <div className="flex justify-center mb-[80px] cursor-pointer" >
      <div className="w-[300px]">
        <motion.div 
          initial={{ opacity: 0, y: 200 }}
          animate={titleIsInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.5}}
        >
          <NavLink to={`/blog/${section}/${index}`}>
            <div className="text-xl text-purple-1 text-center bg-purple-2 h-[100px] flex flex-col justify-center rounded border border-purple-1">
              {details.title}
            </div>
          </NavLink>
        </motion.div>
        <div ref={titleRef}></div>
      </div>
    </div>
  );
}

const Blog = () => {
  const renderNutritionGuidelines = () => {
    let blogcomponents = <></>
    if (Config.responsiveDetails.isWindowSmall) {
      blogcomponents = (
        <div>
          <BlogBox section="nutrition" index={0} />
          <BlogBox section="nutrition" index={1} />
          <BlogBox section="nutrition" index={2} />
          <BlogBox section="nutrition" index={3} />
          <BlogBox section="nutrition" index={4} />
          <BlogBox section="nutrition" index={5} />
          <BlogBox section="nutrition" index={6} />
          <BlogBox section="nutrition" index={7} />
          <BlogBox section="nutrition" index={8} />
          <BlogBox section="nutrition" index={9} />
          <BlogBox section="nutrition" index={10} />
          <BlogBox section="nutrition" index={11} />
          <BlogBox section="nutrition" index={12} />
        </div>
      );
    } else {
      blogcomponents = (
        <div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><BlogBox section="nutrition" index={0} /></div>
            <div className="ml-[40px] mr-[40px]"><BlogBox section="nutrition" index={1} /></div>
            <div className="ml-[40px]"><BlogBox section="nutrition" index={2} /></div>
          </div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><BlogBox section="nutrition" index={3} /></div>
            <div className="ml-[40px] mr-[40px]"><BlogBox section="nutrition" index={4} /></div>
            <div className="ml-[40px]"><BlogBox section="nutrition" index={5} /></div>
          </div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><BlogBox section="nutrition" index={6} /></div>
            <div className="ml-[40px] mr-[40px]"><BlogBox section="nutrition" index={7} /></div>
            <div className="ml-[40px]"><BlogBox section="nutrition" index={8} /></div>
          </div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><BlogBox section="nutrition" index={9} /></div>
            <div className="ml-[40px] mr-[40px]"><BlogBox section="nutrition" index={10} /></div>
            <div className="ml-[40px]"><BlogBox section="nutrition" index={11} /></div>
          </div>
          <div className="flex justify-center">
            <div><BlogBox section="nutrition" index={12} /></div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="text-2xl text-purple-1 mb-[40px] flex justify-center w-full">
          Parents Nutrition Guidelines
        </div>
        {blogcomponents}
      </div>
    )
  }

  const renderGeneral = () => {
    let blogcomponents = <></>
    if (Config.responsiveDetails.isWindowSmall) {
      blogcomponents = (
        <div>
          <BlogBox section="general" index={0} />
          <BlogBox section="general" index={1} />
        </div>
      );
    } else {
      blogcomponents = (
        <div>
          <div className="flex justify-center">
            <div className="mr-[40px]"><BlogBox section="general" index={0} /></div>
            <div className="ml-[40px]"><BlogBox section="general" index={1} /></div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="text-2xl text-purple-1 mb-[40px] flex justify-center w-full">
          General Guidelines
        </div>
        {blogcomponents}
      </div>
    )
  }

  return (
    <div className="m-[10px]">
      <div className="text-3xl text-purple-1 mb-[40px]">Blogs</div>
      {renderNutritionGuidelines()}
      {renderGeneral()}
    </div>
  )
}

export default Blog;