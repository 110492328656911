import { Config } from "../../config";
import LogoIcon from "../../images/D4.png";
import { NavLink } from "react-router-dom";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilPhoneVolume from "@iconscout/react-unicons/icons/uil-phone-volume";

const Header = () => {

  const renderIcon = () => {
    return (
      <div className="flex">
        <NavLink to="/home">
          <div className="w-[100px] h-[115px]"><img src={LogoIcon} alt="Icon" width={100} height={100}/></div>
        </NavLink>
        <div className="mt-[25px] sm:mt-[35px]">
          <div className="text-3xl font-bold sm:text-5xl sm:font-extrabold text-purple-1">
            {Config.nameDetails.title}
          </div>
          <div className="w-[175px] sm:w-auto sm:text-xl font-bold text-red-1">
            {Config.nameDetails.subTitle}
          </div>
        </div>
      </div>
    );
  }

  const renderContactDetails = () => {
    if (Config.responsiveDetails.isWindowSmall) {
      return (
        <div className="mr-[40px] mt-[30px] text-center text-white text-lg">
          <div className="flex bg-purple-1 rounded-3xl py-[2px] px-[10px]">
            <a href={`tel:${Config.contactDetails.phone}`} target="_blank" rel="noreferrer"><UilPhoneVolume /></a>
          </div>
          <div className="flex bg-purple-1 rounded-3xl py-[2px] px-[10px] mt-[10px]">
            <a href={`mailto:${Config.contactDetails.email}`} target="_blank" rel="noreferrer"><UilEnvelope /></a>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mr-[40px] mt-[30px] text-white text-lg w-[300px] text-center">
          <div className="bg-purple-1 rounded-3xl py-[2px] px-[10px] w-[230px]">
            <a href={`tel:${Config.contactDetails.phone}`} target="_blank" rel="noreferrer">
              <div className="flex justify-center">
                <UilPhoneVolume />
                <div className="ml-[5px]">{Config.contactDetails.phone}</div>
              </div>
            </a>
          </div>
          <div className="bg-purple-1 rounded-3xl py-[2px] px-[10px] mt-[10px] w-[290px]">
            <a href={`mailto:${Config.contactDetails.email}`} target="_blank" rel="noreferrer">
              <div className="flex justify-center">
                <UilEnvelope />
                <div className="ml-[5px]">{Config.contactDetails.email}</div>
              </div>
            </a>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      {/* <div className="w-100vw h-[50px] sm:h-[100px]">
        <svg className="sm:h-[100px] h-[50px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 90" x="0px" y="0px" width="100%" preserveAspectRatio="none">
          <path fill="#e95862" fillOpacity="1" style={{transformBox: "fill-box"}} 
            d="M 0 0 L 0 90 C 75 60 350 45 650 55 C 1000 65 1300 40 1400 0 L 0 0 Z"></path>
          <path fill="#465580" fillOpacity="1" style={{transformBox: "fill-box"}} 
            d="M 0 0 L 600 0 C 530 10 185 0 0 50 L 0 0 Z"></path>
          </svg>
      </div> */}
      <div>
        <nav className="flex justify-between h-[120px]">
          {renderIcon()}
          {renderContactDetails()}
        </nav>
      </div>
    </div>
  )
}

export default Header;